import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/layouts/Default/index.js";
import { Stack, Button } from '../components';
import { FaExternalLinkAlt, FaDownload } from 'react-icons/fa';
export const metadata = {
  title: 'Press'
};
export const _frontmatter = {};
const layoutProps = {
  metadata,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`CheaprEats Press Kit 📷`}</h1>
    <h2>{`Company Info`}</h2>
    <h3>{`Our Mission`}</h3>
    <p>{`Our mission is to bring affordable food options to university students all
over Canada. We understand that in an increasingly-expensive society,
cutting back on food expenses could potentially ease a student's financial
pressure, allowing them to maintain a healthy balance between
working and studying.`}</p>
    <h3>{`What is CheaprEats?`}</h3>
    <p>{`CheaprEats is a mobile ordering platform with a focus on operational
efficiency across the board. With improved menu discovery, in app tipping
and a more visual experience we offer business with increased revenue,
happier employees and pleased customers. We also provide a large amount of
free digital marketing through our social media outlets, on the ground
strategies and contests which spill off into sales for your business!`}</p>
    <h2>{`Corporate Responsibility`}</h2>
    <p>{`At CheaprEats, we take corporate responsibility quite seriously!
Here are a few of the ways we strive to do this.`}</p>
    <h3>{`Surfacing Nutritional Information`}</h3>
    <p>{`We provide an in-depth research of all the nutritional information of
our vendors so that consumers can make the best and healthiest decisions
as well as be informed about what's in their meal.`}</p>
    <p>{`CheaprEats also provides dietary warnings on all foods on our app so that
people with certain dietary restrictions are informed throughout
their ordering process.`}</p>
    <h3>{`Promoting Sustainability`}</h3>
    <p>{`CheaprEats provides sustainability information regarding food packaging and
materials. Whether that coffee cup or soup container in your meal
is recyclable, be in the know!`}</p>
    <h3>{`No Vendor Left Behind Policy`}</h3>
    <p>{`In today's world, having a digital presence is a must but not every small
business can afford one especially family owned business with low margins
who are supporting the community. We provide special pricing just for these
vendors to ensure strong development not just of their business but of
the economic stability and growth of that community.`}</p>
    <h3>{`Diversity & Inclusion`}</h3>
    <p>{`As a multicultural team, we strongly support diversity and inclusion throughout
everything we do, not just as our values in hiring processes. We strive to
acquire the best talent possible, regardless of which walk of life you come from.`}</p>
    <h2>{`Resources`}</h2>
    <p>{`Download Assets contains all relevant asset files compressed into a .zip file.
Design Guidelines contains more in-depth resources regarding brand usage,
individual asset downloads, and logo do's and don'ts.`}</p>
    <Stack style={{
      marginTop: '1rem'
    }} mdxType="Stack">
    <Button as='a' href='/owo.gif' download='assets' leftIcon={FaDownload} mdxType="Button">
        Download Assets
    </Button>
    <Button as='a' href='/owo.gif' leftIcon={FaExternalLinkAlt} mdxType="Button">
        Design Guidelines
    </Button>
    </Stack>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      